import { EmployeeActive } from "types/EmployeeActive";
import { EmployeeActiveFast } from "types/EmployeeActiveFast";
import { ScoutTag } from "types/ScoutTag";
import { LandingPageProject, LandingPageProjectAPI } from "types/Project";
import { LandingPageSkill, LandingPageSkillAPI } from "types/Skill";
import { SkillCategory } from "types/SkillCategory";
import {
  EmployeeSkillDetail,
  EmployeeSkillDetailAPI
} from "types/EmployeeSkillDetail";
import { SkillProject, SkillProjectAPI } from "types/SkillProject";
import {
  EmployeeCompletion,
  EmployeeCompletionAPI
} from "types/EmployeeCompletion";
import { Capability, LandingPageCapabilityAPI } from "types/Capability";
import { Industry, LandingPageIndustryAPI } from "types/Industry";
import {
  EmployeePromptResponse,
  EmployeePromptResponseDTO
} from "../types/EmployeePromptResponse";
import { 
  IndustryExperienceAPI,
  IndustryExperience
} from "types/IndustryExperience";
import { 
  ClientIndustryAPI,
  ClientIndustry
} from "types/ClientIndustry";

export const convertToEmployeeActive = (
  eaf: EmployeeActiveFast[]
): EmployeeActive[] => {
  return eaf.map(e => {
    return {
      id: e.i,
      firstName: e.f,
      lastName: e.n,
      fullName: e.f + " " + e.n,
      practice: e.r,
      businessUnit: e.b,
      title: e.t,
      level: e.v,
      location: e.l,
      region: e.a,
      entity: e.e,
      major: e.m,
      minor: e.o,
      clients: e.c,
      projects: e.p,
      hours: e.h,
      endDate: e.d,
      advancedSearch: null,
      staffingPreferences: e.s
    };
  });
};

export const convertLandingsPageProject = (
  lpp: LandingPageProjectAPI[]
): LandingPageProject[] => {
  return lpp.map(p => {
    return {
      clientId: p.a,
      clientName: p.b,
      location: p.c,
      engagementName: p.d,
      projectId: p.e,
      projectName: p.f,
      startDate: p.g,
      endDate: p.h,
      stage: p.i,
      engagementManagerId: p.j,
      engagementManager: p.k,
      projectManagerId: p.l,
      projectManager: p.m,
      oicId: p.n,
      oic: p.o,
      active: p.p,
      practice: p.q, // TODO: Remove for Projector go-live
      projectorCode: p.r,
      projectorUid: p.s
    };
  });
};

export const convertLandingsPageSkill = (
  lps: LandingPageSkillAPI[],
  categories: SkillCategory[],
  scoutTags: ScoutTag[]
): LandingPageSkill[] => {
  let skills: LandingPageSkill[] = lps.map(skill => {
    let category = categories.find(cat => skill.d === cat.id);
    let tag: ScoutTag[];
    if (scoutTags.length === 0) {
      tag = [];
    } else {
      tag = skill.f.map(id => {
        let pt = scoutTags.find(pt => id === pt.id);
        if (pt !== undefined) {
          return pt;
        } else {
          return {
            id: 0,
            name: "",
            description: "",
            displayOrder: 0,
            inactive: false
          };
        }
      });
    }
    return {
      id: skill.a,
      name: skill.b,
      description: skill.h,
      parentId: skill.c,
      parentSkill: null,
      category: category !== undefined ? category.name : "",
      categoryId: skill.d,
      active: skill.e,
      tags: tag,
      employeeCount: skill.g
    };
  });
  skills = skills.map(skill => {
    if (skill.parentId) {
      let parent = skills.find(s => s.id === skill.parentId);
      if (parent !== undefined) {
        skill.parentSkill = parent;
      }
    }
    return skill;
  });
  return skills;
};

export const convertLandingsPageCapability = (
  lpc: LandingPageCapabilityAPI[]
): Capability[] => {
  return lpc.map(c => {
    return {
      id: c.a,
      name: c.b,
      description: c.c,
      inactive: c.d,
      employeePracticeId: c.e,
      employeeCount: c.f,
      majorCount: c.g,
      minorCount: c.h
    };
  });
};

export const convertLandingsPageIndustry = (
  lpi: LandingPageIndustryAPI[]
): Industry[] => {
  return lpi.map(i => {
    return {
      id: i.a,
      name: i.b,
      description: i.c,
      inactive: i.d,
      industrySectorId: i.e,
      employeeCount: i.f,
      majorCount: i.g,
      minorCount: i.h
    };
  });
};

export const convertEmployeeSkillDetail = (
  esd: EmployeeSkillDetailAPI[]
): EmployeeSkillDetail[] => {
  return esd.map(d => {
    return {
      id: d.a,
      name: d.b,
      practice: d.c,
      major: d.d,
      minor: d.e,
      position: d.f,
      office: d.g,
      experienceLevel: d.h,
      duration: d.i,
      lastUsedDate: d.j,
      interest: d.k
    };
  });
};

export const convertSkillProject = (sp: SkillProjectAPI[]): SkillProject[] => {
  return sp.map(p => {
    return {
      clientId: p.a,
      client: p.b,
      projectId: p.c,
      project: p.d,
      startDate: p.e,
      endDate: p.f,
      active: p.g
    };
  });
};

export const convertEmployeeCompletion = (
  ec: EmployeeCompletionAPI[]
): EmployeeCompletion[] => {
  return ec.map(e => {
    return {
      id: e.a,
      name: e.b,
      practice: e.c,
      major: e.d,
      position: e.e,
      office: e.f,
      projectRoles: e.g,
      skills: e.h,
      skillsUpdated: e.i,
      certifications: e.j,
      certificationsUpdated: e.k,
      prompts: e.l,
      promptsUpdated: e.m,
      email: e.n,
      level: e.o,
      careerCoachName: e.p,
      careerCoachEmail: e.q
    };
  });
};

export const convertEmployeePromptResponse = (
  er: EmployeePromptResponseDTO[]
): EmployeePromptResponse[] => {
  return er.map(e => {
    return {
      id: e.a,
      firstName: e.b,
      lastName: e.c,
      fullName: e.b + " " + e.c,
      sortName: e.c + ", " + e.b,
      email: e.d,
      practice: e.e,
      position: e.f,
      location: e.g,
      response: e.h,
      value: e.i
    };
  });
};

export const convertIndustryExperience = (
  ie: IndustryExperienceAPI[]
): IndustryExperience[] => {
  return ie.map(i => {
    return {
      id: i.a,
      name: i.b,
      practice: i.c,
      major: i.d,
      position: i.e,
      office: i.f,
      email: i.g,
      industryExperience: i.h
    };
  });
};

export const convertClientIndustry = (
  ci: ClientIndustryAPI[]
): ClientIndustry[] => {
  return ci.map(c => {
    return {
      id: c.a,
      name: c.b,
      practice: c.c,
      major: c.d,
      position: c.e,
      office: c.f,
      email: c.g,
      clientName: c.h,
      clientIndustry: c.i
    };
  });
};
